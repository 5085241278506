<template>
  <p-loader v-if="loading" />
  <div
    v-else
    class="w-full overflow-auto"
  >
    <div v-if="items.customer.balance && items.customer.balance.length > 0">
      <h1>{{ customer.name }} {{ $tk('Reports.CustomersInventoryAtSR', true) }}</h1>

      <table class="mt-6 data-table report-table">
        <thead>
          <tr>
            <th v-html="$tk('Reports.Customer')"></th>
            <th v-html="$tk('Reports.Product')"></th>
            <th>{{ ' ' }}</th>
            <th v-html="$tk('Reports.Quality')"></th>
            <th>{{ ' ' }}</th>
            <th
              class="right"
              v-html="$tk('Reports.Quantity')"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items.customer.balance"
            :key="index"
          >
            <td>{{ item.customerName }}</td>
            <td>{{ item.productId }}</td>
            <td>{{ item.productName }}</td>
            <td>{{ item.qualityId }}</td>
            <td>{{ item.qualityName }}</td>
            <td class="text-right">{{ item.quantity }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="items.location.balance && items.location.balance.length > 0">
      <h1 class="mt-6">{{ customer.name }} {{ $tk('Reports.CustomersInventoryAtCustomers', true) }}</h1>

      <table class="mt-6 data-table report-table">
        <thead>
          <tr>
            <th v-html="$tk('Reports.Customer')"></th>
            <th v-html="$tk('Reports.Location')"></th>
            <th v-html="$tk('Reports.Product')"></th>
            <th>{{ ' ' }}</th>
            <th v-html="$tk('Reports.Quality')"></th>
            <th>{{ ' ' }}</th>
            <th
              class="right"
              v-html="$tk('Reports.Quantity')"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in order(items.location.balance, ['locationName'])"
            :key="index"
          >
            <td>{{ item.customerName }}</td>
            <td>{{ item.locationName }}</td>
            <td>{{ item.productId }}</td>
            <td>{{ item.productName }}</td>
            <td>{{ item.qualityId }}</td>
            <td>{{ item.qualityName }}</td>
            <td class="text-right">{{ item.quantity }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <portal to="header">
      <div class="ml-1 text-xl font-medium text-gray-900">{{ $tk('Reports.BalanceControlCommission', true) }}</div>
    </portal>

    <portal to="actions">
      <PButton
        color="secondary"
        icon="file-excel"
        :loading="isDownloading"
        @click="onDownload"
      >
        Excel
      </PButton>
    </portal>

  </div>
</template>

<script>
import { format } from "date-fns"
import { mapGetters } from 'vuex'
import { orderBy } from 'lodash'
import fileDownload from 'js-file-download'
import http from "@/utilities/http"

import PLoader from "@/components/PLoader.vue"

export default {
  name: "Commisions",
  components: { PLoader },
  data() {
    return {
      items: [],
      loading: true,
      isDownloading: false
    }
  },
  computed: {
    ...mapGetters([
      'customer',
      'location',
      'user'
    ]),
    params() {
      // Permissions: If Report on customerlevel, show all, else show only location
      const obj = { isReport: true }
      if (this.user.reportLevel > 0) {
        obj.customerId = this.customer.id
      } else {
        obj.locationId = this.location.id
      }
      return obj
    }
  },
  methods: {
    order(items, value) {
      return orderBy(items, value)
    },
    async onDownload() {
      this.isDownloading = true
      const data = await http.get("InventoryCommission", { params: { ...this.params, excel: "download" }, responseType: "blob" })
      fileDownload(data, `${format(new Date(), "yyyy-MM-dd")}_commission.xlsx`)
      this.isDownloading = false
    },
  },
  async created() {
    this.items = await http.get("InventoryCommission", { params: this.params });
    this.loading = false
  },
}
</script>